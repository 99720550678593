import React from 'react';
import type { CardProps } from '@wolfejs/ui/Card';
import { Card } from '@wolfejs/ui/Card';
import { UIColors, UISizes } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';

export type DashboardPanelProps = CardProps & {
  title?: React.ReactNode;
};

export const DashboardPanel = ({
  title,
  size = UISizes.xl,
  className,
  classNames,
  color = UIColors.default,
  children,
  ...otherProps
}: DashboardPanelProps) => {
  return (
    <Card
      title={title}
      noPad
      color={color}
      className={cn(`dark:bg-${color}-900 bg-white`, className)}
      elevation={3}
      classNames={{
        ...classNames,
        content: cn(classNames?.content),
        title: cn(
          'font-bold',
          {
            ['text-xs']: size === UISizes.xs,
            ['text-sm']: size === UISizes.sm,
            ['text-base']: size === UISizes.md,
            ['text-2xl']: size === UISizes.xl,
          },
          classNames?.title
        ),
        wrapper: cn('p-5 ', classNames?.wrapper),
      }}
      {...otherProps}
    >
      {children}
    </Card>
  );
};
