import cardsImage from '@wolfejs/assets/cards.png';
import type { UserSigninPayload } from '@wolfejs/ms-api-js/src/types/auth';
import { Form } from '@wolfejs/ui/Form';
import type { FormSchemaEntry } from '@wolfejs/ui/Form/types';
import { Title } from '@wolfejs/ui/Title';
import { UIColorContext, UIColors, UISizes, UIVariantsExtra } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import * as z from 'zod';
import { DashboardPanel } from '../DashboardPanel';
import {Button} from "@wolfejs/ui/Button";

export type HeroLoginProps = {
  title?: string;
  subTitle?: string;
  color?: string | undefined;
  className?: string;
  cardImageUrl?: string;
  isLoading?: boolean;
  error?: Error | null;
  onForgotPasswordClick?: () => void;
  onSubmit?: (signinPayload: UserSigninPayload) => Promise<void>;
};

type SignInFormValues = { password: string; email: string; rememberMe: boolean };

export const HeroLogin = ({
  title,
  className,
  color = UIColorContext.primary,
  subTitle,
  cardImageUrl,
  isLoading,
  error,
  onForgotPasswordClick,
  onSubmit,
}: HeroLoginProps) => {
  const signInFormSchema: FormSchemaEntry[] = [
    {
      name: 'email',
      label: 'Your email',
      type: 'email',
      validation: z.string().email(),
      props: {
        autoComplete: 'username',
        placeholder: 'name@example.com',
      },
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      validation: z.string().min(4),
      props: {
        autoComplete: 'current-password',
        classNames: {
          helperText: 'text-right',
        },
        // Not needed for MVP, will be used later
        helperText: (
          <Button
            type="button"
            variant={UIVariantsExtra.link}
            size={UISizes.sm}
            color={UIColors.primary}
            classNames={{ content: 'gap-2' }}
            onClick={onForgotPasswordClick}
          >
            Forgot password?
          </Button>
        ),
      },
    },
    // TODO: bring back when ready for the remember me functionality
    // {
    //   name: 'rememberMe',
    //   type: 'checkbox',
    //   props: {
    //     labelAppend: 'Remember this device',
    //     color: UIColors.primary,
    //     labelClasses: 'text-default-500 text-sm font-normal',
    //   },
    // },
  ];

  return (
    <section
      className={cn(
        'bg-background @container relative flex h-screen w-full items-center justify-center',
        `bg-${color} text-white`,
        `${className}`
      )}
    >
      <div className={cn(`relative z-10 h-full p-16`)}>
        <div
          className={cn(
            'content items-top @4xl:h-full container flex w-full flex-col justify-around gap-12',
            // Breakpoints
            '@4xl:flex-row'
          )}
        >
          <div className="flex max-w-[650px] flex-col gap-8">
            <div className="flex flex-col gap-8">
              {title && <div className="text-6xl font-black">{title}</div>}
              {subTitle && <div className="text-xl font-normal">{subTitle}</div>}
            </div>
          </div>
          <div className="flex flex-col justify-center gap-6">
            <DashboardPanel
              className="border-primary flex min-w-[348px] max-w-[448px] flex-col items-start justify-center gap-5 border shadow"
              classNames={{ wrapper: 'w-full p-8' }}
            >
              <Title as="h3" className="text-default-900 dark:text-default-200 mb-5 font-semibold">
                Sign In
              </Title>
              <Form<SignInFormValues>
                schema={signInFormSchema}
                submitButtonText="Sign In"
                hasSubmitButton
                noDividers
                error={error ? new Error('Invalid Credentials', { cause: error?.cause }) : null}
                isLoading={isLoading}
                classNames={{ actions: 'mt-5' }}
                fieldsetLayoutClasses="flex flex-col gap-5"
                submitButtonProps={{ fullWidth: true, size: UISizes.sm, className: 'px-5 py-2.5' }}
                onSubmit={onSubmit}
              />
            </DashboardPanel>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 z-0 w-full origin-top-left">
        <img className="m-0 w-full" alt="Cards" src={cardImageUrl || cardsImage.src} />
      </div>
    </section>
  );
};
